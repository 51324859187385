/**
 * Extract colors from matrix
 *
 * @returns {Object}
 */
export function getStateColors (matrix) {
  return matrix.reduce((acc, group) => {
    group.buttons.forEach(btn => {
      acc[btn.id] = group.color
    })

    return acc
  }, {})
}

/**
 * Get Route Options
 *
 * @param {String} route
 *
 * @returns {Object}
 */
export function getRouteOptions (route) {
  const appOptions = JSON.parse(localStorage.getItem('appOptions'))

  if (!appOptions?.appSettings || !appOptions?.appSettings?.routeOptions) {
    return {}
  }

  return appOptions.appSettings.routeOptions[route] || {}
}

/**
 * Extract ID
 *
 * @param {object | String | Number} data
 *
 * @returns {String | Number | undefined | null}
 */
export function extractId (data) {
  return !!data && typeof data === 'object'
    ? data.id
    : data
}

/**
 * Generate delivery request from table
 *
 * @param {object} data
 *
 * @returns {object}
 */
export function generateDeliveryRequestFromTable (data) {
  const places = data.places.map(place => {
    return {
      items: place.items.map(item => {
        return {
          extId: item.extId,
          barcodes: [],
          count: item.count,
          dimensions: {
            x: 0,
            y: 0,
            z: 0
          },
          estimatedCost: item.estimatedCost,
          name: item.name,
          payment: item.payment,
          state: 'active',
          weight: item.weight || 1
        }
      })
    }
  })

  const numberOfPlaces = (data.numberOfPlaces || 0) - places.length > 0
    ? data.numberOfPlaces
    : 0

  const additionalPlaces = Array(numberOfPlaces).fill({ items: [] })

  return {
    extId: data.extId,
    recipientName: data.name,
    recipientPhone: data.phone,
    recipientComment: data.recipientComment,
    payment: data.payment,
    sendDate: new Date().toDateString(),
    estimatedCost: data.estimatedCost,
    sender: data.sender,
    rate: data.rate,
    deliveryDate: data.deliveryDate,
    weight: data.weight || 1,
    recipientLocality: data.recipientLocality
      ? data.recipientLocality.id
      : undefined,
    servicePoint: data.servicePoint
      ? data.servicePoint
      : undefined,
    senderName: data.senderName
      ? data.senderName
      : undefined,
    eav: {},
    places: [...places, ...additionalPlaces],
    dimensions: {
      x: 100,
      y: 100,
      z: 100
    },
    recipient: {
      email: data.email,
      name: data.name
    },
    recipientAddress: {
      notFormal: data.notFormal || data.address,
      quarter: data.quarter,
      postcode: data.postcode,
      street: data.street,
      house: data.house,
      locality: data.locality
        ? data.locality.id
        : undefined
    }
  }
}

/**
 * Convert Source Settings
 *
 * @param {object} settings
 *
 * @returns {object}
 */
function convertSourceSettings (settings) {
  return Object.keys(settings).reduce((acc, key) => {
    if (typeof settings[key] === 'object' && !Array.isArray(settings[key])) {
      if (settings[key].id) {
        acc[key] = settings[key].id
        return acc
      }

      acc[key] = convertSourceSettings(settings[key])
      return acc
    }

    acc[key] = settings[key]
    return acc
  }, {})
}

/**
 * Convert Source Integration
 *
 * @param {object} integration
 *
 * @returns {object}
 */
export function convertSourceIntegration (integration = {}) {
  const model = {
    ...integration,
    settings: convertSourceSettings(integration.settings || {})
  }

  if (model.settings.Products && Array.isArray(model.settings.Products.shops)) {
    model.settings.Products.shops = model.settings.Products.shops.filter(x => x.id && x.extId)
  }

  return model
}

/**
 * Get Image
 *
 * @param {String} logo
 *
 * @returns {String}
 */
export function getLogoUrl (logo) {
  const isFullUrl = /^https?:\/\//.test(logo);

  if (isFullUrl) {
    return logo;
  } else {
    return this.appOptions.defaultServer + logo
  }
}
