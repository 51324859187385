<template>
  <div
    class="row items-center"
    :class="hasMaxWidth ? 'full-width' : 'justify-center'"
  >
    <div class="q-mr-sm q-my-sm col" :style="`width: ${inputWidth}%;`">
      <q-form @submit="onSubmit">
        <div class="row items-center">
          <div class="col">
            <q-input
              ref="barcodeInput"
              v-model="barcode"
              standout="bg-teal text-white"
              class="q-mr-sm q-mb-none"
              type="text"
              :label="$t('Barcode')"
              :inputmode="inputmode"
              :disable="disabled"
              @focus="onFocus"
              @blur="onFocusOut"
              @dblclick="handleClick"
            />
          </div>

          <div v-if="!isHiddenCamera">
            <q-btn
              outline
              size="lg"
              style="color: goldenrod; max-width: 100%"
              icon="camera"
              @click="openCloseCamera"
            />
          </div>
        </div>
      </q-form>
    </div>

    <div v-if="settings.catchAll">
      <q-toggle
        :model-value="catchAll"
        :label="$t('Catch all')"
        :disable="disabled"
        :class="$q.dark.isActive ? 'text-white' : ''"
        @update:model-value="handleCatchChange"
      />
    </div>

    <q-dialog
      v-model="isOpenCamera"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="bg-primary text-white">
        <q-bar>
          <q-toolbar-title>
            {{ $t("Barcode") }}
          </q-toolbar-title>

          <q-space />

          <q-btn v-close-popup dense flat icon="close">
            <q-tooltip content-class="bg-white text-primary">
              {{ $t("Close") }}
            </q-tooltip>
          </q-btn>
        </q-bar>

        <barcode-scanner v-if="isOpenCamera" @change="logIt" />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Components
import BarcodeScanner from './../barcode-scanner/BarcodeScanner'

// Quasar
import { debounce } from 'quasar'

export default {
  name: 'BarcodeInput',
  emits: ['barcode'],
  components: {
    BarcodeScanner
  },
  props: {
    settings: {
      type: Object,
      default () {
        return {
          catchAll: true,
          hideKeyboard: false
        }
      }
    },
    hasMaxWidth: {
      type: Boolean,
      default () {
        return true
      }
    },
    outFocused: {
      type: Boolean,
      default () {
        return false
      }
    },
    isHiddenCamera: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    catchAllFalse: {
      type: Boolean,
      default () {
        return false
      }
    },
  },
  data () {
    return {
      isOpenCamera: false,
      barcode: '',
      isFocused: false,
      catchAll:  true ,
      inputmode: 'text',
      readerSize: {
        width: 400,
        height: 480
      },
      token: null,
      inputWidth: 80,
      subscriber: null
    }
  },
  watch: {
    settings: {
      handler (newVal, oldVal) {
        if (newVal.catchAll !== oldVal.catchAll) {
          this.recognizedListener()
        }

        if (newVal.hideKeyboard !== oldVal.hideKeyboard) {
          this.recognizedInputmode()
        }
      },
      deep: true
    },
    catchAllFalse: function (newVal) {
        this.catchAll = newVal
    }
  },
  mounted () {
    this.attachCatchAll()
    this.recognizedInputmode()

    this.resize()
    this.resize = debounce(this.resize.bind(this), 500)

    window.addEventListener('resize', this.resize)

    this.catchAll = !this.catchAllFalse
    this.subscriber = this.$eventBus.subscribe('catchAll', data => {
      this.catchAll = data.catchAll
    })
  },
  unmounted () {
    document.removeEventListener('keydown', this.handleAll)
    window.removeEventListener('resize', this.resize)
    this.subscriber.unsubscribe()
  },
  methods: {
    recognizedListener () {
      if (this.settings.catchAll) {
        this.attachCatchAll()
      } else {
        this.unattachCatchAll()
      }
    },
    recognizedInputmode () {
      this.inputmode = !this.settings.hideKeyboard
        ? 'text'
        : 'none'
    },
    unattachCatchAll () {
      document.removeEventListener('keydown', this.handleAll)
    },
    attachCatchAll () {
      if (!this.settings.catchAll) {
        return
      }

      this.handleAll = this.handleAll.bind(this)
      document.addEventListener('keydown', this.handleAll)
    },
    resize () {
      this.inputWidth = window.innerWidth < 600 && this.settings.catchAll
        ? 60
        : 80
    },
    openCloseCamera () {
      this.isOpenCamera = !this.isOpenCamera
    },
    openCamera () {
      this.isOpenCamera = true
    },
    logIt (barcode) {
      this.barcode = barcode
      this.onSubmit()
      this.isOpenCamera = false
    },
    handleClick () {
      if (!this.settings.hideKeyboard) {
        return
      }

      this.inputmode = 'text'
    },
    onFocus () {
      this.catchAll = false
    },
    onFocusOut () {
      if (this.settings.hideKeyboard) {
        this.inputmode = 'none'
      }

      this.catchAll = true
      this.isFocused = false
    },
    handleCatchChange () {
      this.catchAll = !this.catchAll
    },
    handleAll (e) {
      if (!this.catchAll || this.outFocused || e.ctrlKey || e.altKey) {
        return
      }

      this.$refs.barcodeInput.focus()
    },
    createBarcodeData (barcode) {
      const raw = `${barcode}`.trim()
      const expr = /[A-z/]+\/([0-9A-z/]+)/

      if (!raw.match(expr)) {
        return {
          raw,
          value: raw,
          type: ''
        }
      }

      const data = raw
        .split('*')[0]
        .split('/')
        .reduce((acc, value) => {
          if (isNaN(Number(value)) && value.length <= 3 && !acc.value) {
            acc.type += `${value}/`
          } else if (isNaN(Number(value)) && value.length > 3 && !acc.value) {
            acc.value += `${value}/`
          } else {
            acc.value += `${value}/`

          }

          return acc
        }, { value: '', type: '', raw })

      data.value = data.value.slice(0, data.value.length - 1)
      data.type = data.type.slice(0, data.type.length - 1)

      return data
    },
    onSubmit (e) {
      if (e) {
        e.preventDefault()
      }

      // if (!this.barcode) {
      //   return
      // }

      this.$emit('barcode', this.createBarcodeData(this.barcode))
      this.barcode = ''
    }
  }
}
</script>
